<template>
  <v-container class="w-100 px-0" fluid>
    <template v-if="pageContent != null && defaultTextContent != null">
      <!-- HEADER -->
      <v-row class="mx-0 px-0">
        <v-img :src="`${apiPublicUrl}${pageContent.image}`" min-height="400px" max-height="400px" class="header blur overlay">
          <v-row class="fill-height mt-10" align="center" justify="center">
            <div class="white--text text-center">
              <h1 class="text-lg-h2 text-sm-h4 text-h6 text-uppercase text-no-break li-text-shadow font-weight-bold mb-3" v-html="pageContent.headerTitle" />
              <div class="text-sm-h5 text-p white--text text-center li-text-shadow" v-html="pageContent.headerSubTitle" />
            </div>
          </v-row>
        </v-img>
      </v-row>
    </template>
    <template v-else>
      <div class="text-center w-100 d-flex" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="m-auto" />
      </div>
    </template>
  </v-container>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "",
  name: "HqDepartment",
  data() {
    return {
      apiPublicUrl: API_URL,
      filterPlaces: null,
      filterEmployment: null,
      filterDepartment: null,
      filters: {
        Field: "departments",
        Values: [decodeURI(this.fullUrl)],
      },
    };
  },
  computed: {
    departmentNoOfItems() {
      if (this.isAppMobile) {
        return 1;
      } else if (this.isAppTablet) {
        return 3;
      } else {
        return 4;
      }
    },
    departmentNoOfCols() {
      if (this.isAppMobile) {
        return 12;
      } else if (this.isAppTablet) {
        return 4;
      } else {
        return 3;
      }
    },
    location: {
      get() {
        return "hq";
      },
    },
    page() {
      return this.$store.getters["liApiContent/getPage"](this.fullUrl);
    },
    pageContent() {
      return this.page != null ? JSON.parse(this.page.content) : null;
    },
    jobs() {
      return this.$store.getters["liApiStore/getJobs"](this.location);
    },
    filteredJobs() {
      if (this.jobs != null && this.$route.params.departmentLine) {
        let filtered = [];
        this.jobs.forEach((job) => {
          if (job.departments != undefined && job.departments.length > 1) {
            job.departments.forEach((department) => {
              if (department.toLowerCase() == this.$route.params.departmentLine) {
                filtered.push(job);
              }
            });
          } else if (job.departments != undefined && job.departments.length == 1) {
            if (job.departments[0].toLowerCase() == this.$route.params.departmentLine) {
              filtered.push(job);
            }
          }
        });
        return filtered;
      } else {
        return null;
      }
    },
    places() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.address != null && job.address != undefined && job.address != "" && job.address.city != "") {
            if (!filterItems.some((item) => item.line === job.address.line)) {
              filterItems.push(job.address);
            }
          }
        });
        return filterItems.sort((a, b) => (a.city > b.city ? 1 : a.city === b.city ? (a.size > b.size ? 1 : -1) : -1));
      } else {
        return null;
      }
    },
    employments() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.employment != null && job.employment != undefined && job.employment != "") {
            if (job.employment.length > 1) {
              job.employment.forEach((employment) => {
                if (!filterItems.includes(employment)) {
                  filterItems.push(this.capitalizeFirstLetter(employment));
                }
              });
            } else {
              if (!filterItems.includes(job.employment[0])) {
                filterItems.push(this.capitalizeFirstLetter(job.employment[0]));
              }
            }
          }
        });
        return filterItems.sort();
      } else {
        return null;
      }
    },
    departments() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.departments != null && job.departments != undefined && job.departments != "") {
            if (job.departments.length > 1) {
              job.departments.forEach((item) => {
                if (!filterItems.includes(item)) {
                  filterItems.push(item);
                }
              });
            } else {
              if (!filterItems.includes(job.departments[0])) {
                filterItems.push(job.departments[0]);
              }
            }
          }
        });
        return filterItems.sort();
      } else {
        return null;
      }
    },
  },
  watch: {
    page(val) {
      if (val != null) {
        this.$title = this.page.pagetitle;
      }
    },
    filterPlaces(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "address.city");
        this.filters.push({ Field: "address.city", Values: [val.city] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "address.city");
      }
    },
    filterEmployment(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "employment");
        this.filters.push({ Field: "employment", Values: [val.toLowerCase()] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "employment");
      }
    },
    filterDepartment(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "departments");
        this.filters.push({ Field: "departments", Values: [val] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "departments");
      }
    },
  },
  created() {
    this.fetchURL();
  },
  mounted() {
    // console.log("START -- HQ JOBS VIEW");
    // console.log("language", this.language);
    // console.log("website", this.website);
    // console.log("fullUrl", this.fullUrl);
    // console.log("cultureItems", this.cultureItems);
    // console.log("END -- HQ JOBS VIEW");
    this.setupPage();
  },
  methods: {
    setupPage() {
      this.fetchDepartmentData();
      this.loadJobs();
      if (this.departmentItems == null) {
        this.fetchDepartmentItems();
      }
      if (this.cultureItems == null) {
        this.fetchCultureItems();
      }
    },
    loadJobs() {
      this.$store.dispatch("liApiStore/GET_JOBS", {
        location: this.location,
        language: this.language,
      });
    },
  },
};
</script>
